import './Project.styl'
import DomComponent from 'abstractions/DomComponent'
import store from 'controllers/store'
import { el } from '@internet/dom'
import scrollTo from 'scroll-to-element'

export default class Project extends DomComponent {
  didMount () {
    this.infosVisible = false
    this.scroll = 0

    this.addRef('projectLink', document.querySelector('header.site-nav .nav-item--projects a'))
    this.addRef('infosLink', document.querySelector('.project-infos .infos-link'))

    this.imgs = [].slice.call(this.refs.base.querySelectorAll('.section-img'))

    this.initialLinkText = this.refs.projectLink.textContent
    this.refs.projectLink.textContent = ''
    this.refs.projectLink.style.position = 'relative'
    this.refs.projectLink.parentNode.classList.add('active')

    const css = {
      position: 'absolute',
      left: 0
    }

    this.addRef('spanGeneric', el('span', { classname: 'span-generic', textContent: this.initialLinkText, css }))
    this.addRef('spanProject', el('span', { classname: 'span-generic', textContent: window.__arnhembraun__.projectName, css }))

    this.refs.projectLink.appendChild(this.refs.spanGeneric)
    this.refs.projectLink.appendChild(this.refs.spanProject)

    this.refs.spanGeneric.style.opacity = 0
    this.refs.spanProject.style.opacity = 1

    this.bindFuncs(['mouseover', 'mouseout', 'toggleInfos', 'close', 'scrollToNext'])
    store.watch('screen.width', this.close)
    this.refs.projectLink.addEventListener('mouseover', this.mouseover)
    this.refs.projectLink.addEventListener('mouseout', this.mouseout)
    this.refs.infosLink.addEventListener('click', this.toggleInfos)

    for (let i = 0; i < this.imgs.length; i++) {
      if (!store.get('device.desktop')) break
      this.imgs[i].addEventListener('click', this.scrollToNext, false)
    }
  }

  scrollToNext (e) {
    e && e.preventDefault()
    let idx = -1
    let epath = e.composedPath ? e.composedPath() : e.path
    for (let i = 0; i < epath.length; i++) {
      if (~this.imgs.indexOf(epath[i])) idx = this.imgs.indexOf(epath[i])
    }
    if (!~idx || !this.imgs[idx + 1]) return
    scrollTo(this.imgs[idx + 1], {
      offset: -80,
      ease: 'in-out-quad',
      duration: 450
    })
  }

  close () {
    this.toggleInfos(undefined, false)
  }

  toggleInfos (e, force) {
    e && e.preventDefault() && e.stopPropagation()

    const oldVisible = this.infosVisible
    this.infosVisible = force !== undefined
      ? !!force
      : !this.infosVisible
    const method = this.infosVisible ? 'add' : 'remove'
    const scrollSaveNeeded = !oldVisible && this.infosVisible && store.get('size').w < store.get('mq.mobile')

    if (scrollSaveNeeded) {
      this.scroll = window.scrollY
    }

    document.body.classList[method]('project-infos--visible')

    if (scrollSaveNeeded) {
      window.scrollTo(0, 0)
    } else if (oldVisible && !this.infosVisible && store.get('size').w < store.get('mq.mobile')) {
      window.scrollTo(0, this.scroll)
      this.scroll = 0
    }
  }

  mouseover (e) {
    e.stopPropagation()
    if (store.get('size').w < store.get('mq.mobile')) return
    this.refs.spanGeneric.style.opacity = 1
    this.refs.spanProject.style.opacity = 0
    this.refs.projectLink.parentNode.classList.remove('active')
  }

  mouseout (e) {
    e.stopPropagation()
    this.refs.spanGeneric.style.opacity = 0
    this.refs.spanProject.style.opacity = 1
    this.refs.projectLink.parentNode.classList.add('active')
  }

  willUnmount () {
    store.unwatch('screen.width', this.close)
    store.unwatch('screen.height', this.close)
    this.refs.projectLink.removeEventListener('mouseover', this.mouseover)
    this.refs.projectLink.removeEventListener('mouseout', this.mouseout)
    this.refs.infosLink.removeEventListener('click', this.toggleInfos)
    for (let i = 0; i < this.imgs.length; i++) {
      if (!store.get('device.desktop')) break
      this.imgs[i].removeEventListener('click', this.scrollToNext)
    }
    this.items = []
    this.imgs = null
  }
}
